<template>
  <div class="tw-h-full tw-w-full tw-flex tw-justify-center">
    <ForgotPasswordComponent />
  </div>
</template>

<script>
import ForgotPasswordComponent from '@/views/auth/ForgotPassword/ForgotPasswordComponent';

export default {
  components: {
    ForgotPasswordComponent,
  },
};
</script>

<style>
</style>
