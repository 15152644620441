<template>
  <div
    class="tw-flex tw-flex-col tw-items-center tw-p-12 auth-container tw-m-auto"
  >
    <div class="tw-w-full">
      <h1 class="tw-font-sans tw-font-bold tw-text-3xl tw-text-center">
        Forgot Password?
      </h1>
      <div
        class="tw-pt-2 tw-text-paragraph tw-flex-grow tw-text-center tw-mt-0 tw-text-base tw-leading-5 tw-font-montserrat-medium"
      >
        Do you remember again?
        <router-link
          :to="{ name: 'SignIn' }"
          class="tw-font-bold tw-text-primary tw-font-montserrat-medium tw-cursor-pointer"
        >
          Sign in
        </router-link>
      </div>
      <template v-if="forgotPasswordEmailSent">
        <div class="tw-mt-4">
          Thanks! We just sent you an e-mail with a new password!
        </div>
      </template>

      <template v-if="!forgotPasswordEmailSent">
        <div
          v-if="error"
          class="tw-border tw-self-center tw-text-sm tw-border-red-500 tw-bg-red-300 tw-p-2 tw-rounded-lg tw-font-sans tw-mt-4"
        >
          {{ error.message }}
          <div v-if="error.code" class="tw-text-xs">{{ error.code }}</div>
        </div>
        <div class="tw-mt-8 md:tw-px-8">
          <InputRow
            label="Email address"
            type="text"
            v-model="email"
            placeholder="name@mail.com"
          />
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-center">
            <v-btn class="tw-w-full" @click="sendNewPassword" color="primary">
              Send a new one
            </v-btn>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import InputRow from '@/views/auth/InputRow';

export default {
  name: 'SignInComponent',
  components: {
    InputRow
  },
  data() {
    return {
      email: '',
      forgotPasswordEmailSent: false,
      error: null
    };
  },
  methods: {
    sendNewPassword() {
      sendPasswordResetEmail(getAuth(), this.email)
        .then(this.handleForgotPasswordSuccess)
        .catch(this.handleForgotPasswordError);
    },
    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      signInWithPopup(getAuth(), provider)
        .then(this.handleSignInSuccess)
        .catch(this.handleSignInError);
    },
    signInWithFacebook() {
      const provider = new FacebookAuthProvider();
      provider.addScope('public_profile');
      provider.addScope('email');

      signInWithPopup(getAuth(), provider)
        .then(this.handleSignInSuccess)
        .catch(this.handleSignInError);
    },
    handleForgotPasswordSuccess() {
      this.forgotPasswordEmailSent = true;
    },
    handleForgotPasswordError(err) {
      switch (err.code) {
      case 'auth/user-not-found':
        return (this.error = {
          message: 'The provided e-mail address is not registered.'
        });
      case 'auth/invalid-email':
        return (this.error = {
          message: 'The e-mail address you provided is invalid.'
        });
      default:
        this.$emit('error', err);
        return (this.error = {
          message:
              'An error has occured. Please check your data and try again.',
          code: err.code
        });
      }
    },
    handleSignInError(err) {
      switch (err.code) {
      case 'auth/popup-closed-by-user':
        return (this.error = null);
      case 'auth/user-not-found':
        return (this.error = {
          message: 'The provided e-mail address is not registered.'
        });
      case 'auth/wrong-password':
        return (this.error = {
          message:
              'The password does not belong to the provided e-mail address.'
        });
      case 'auth/invalid-email':
        return (this.error = {
          message: 'The e-mail address you provided is invalid.'
        });
      default:
        this.$emit('error', err);
        return (this.error = {
          message:
              'An error has occured. Please check your data and try again.',
          code: err.code
        });
      }
    },
    handleSignInSuccess(data) {
      if (!data.providerId && !data.user.emailVerified) {
        return (this.error = {
          message: 'Please verify your e-mail first.'
        });
      }

      this.$emit('success', data);
      this.$router.replace({
        name: 'LandingPage'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.auth-container {
  @include respond-to(sm-xl) {
    width: $screen-sm-min;
  }
}
.v-btn {
  border-radius: 104px !important;
  box-shadow: none !important;
  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  margin-top: 24px;
}

h1 {
  font-family: PoppinsBold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #28262e;
}

</style>
